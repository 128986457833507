import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Outlet,
  useParams,
  useNavigate,
} from "react-router-dom";

class ShutterSamples {
  tonal = {
    path: "tonal-shutter.wav",
    duration: 2.53,
    events: [{ t: 0.4 }, { t: 0.93 }, { t: 1.28 }, { t: 1.69 }],
  };

  crash = {
    path: "crash-shutter.wav",
    duration: 1.66,
    events: [{ t: 0.2 }, { t: 0.339 }, { t: 0.46 }, { t: 0.538 }, { t: 0.662 }],
  };

  initAudio() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    this.aCtx = new AudioContext();

    // Play an empty buffer (for iOS)
    const buffer = this.aCtx.createBuffer(1, 1, 22050);
    const source = this.aCtx.createBufferSource();
    source.buffer = buffer;
    source.connect(this.aCtx.destination);
    // play the file
    source.start(0);
  }

  async load() {
    for (let info of [this.tonal, this.crash]) {
      const res = await fetch(`/blob?folder=samples&uid=${info.path}`);
      const arrayBuf = await res.arrayBuffer();
      const audioBuf = await this.aCtx.decodeAudioData(arrayBuf);

      info.buf = audioBuf;
    }
  }
}

const Main = () => <Outlet />;

class MultiShutter {
  n_rows = 2;
  n_cols = 9;

  shutters = [];

  constructor(opts) {
    this.shutters = [];

    for (let r = 0; r < this.n_rows; r += 1) {
      for (let c = 0; c < this.n_cols; c += 1) {
        const shut = new Shutter(opts);
        shut.v = 70 + this.shutters.length * 15;
        this.shutters.push(shut);
      }
    }
  }

  initAudio() {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    this.aCtx = new AudioContext();

    // Play an empty buffer (for iOS)
    const buffer = this.aCtx.createBuffer(1, 1, 22050);
    const source = this.aCtx.createBufferSource();
    source.buffer = buffer;
    source.connect(this.aCtx.destination);
    // play the file
    source.start(0);

    this.oscs = [];
    this.gains = [];

    for (let i = 0; i < this.shutters.length; i++) {
      const oscillator = this.aCtx.createOscillator();
      oscillator.type = "sine";
      oscillator.frequency.setValueAtTime(
        this.shutters[i].v,
        this.aCtx.currentTime,
      );

      // Create a gain node to control the volume
      const gainNode = this.aCtx.createGain();
      gainNode.gain.setValueAtTime(1, this.aCtx.currentTime);

      oscillator.connect(gainNode);
      gainNode.connect(this.aCtx.destination);

      // Start the oscillator
      oscillator.start();

      this.oscs.push(oscillator);
      this.gains.push(gainNode);
    }
  }

  stopAudio() {
    for (let osc of this.oscs) {
      osc.stop();
    }
  }

  render($canvas) {
    const w = this.n_cols * 210 + 60;
    const h = this.n_rows * 210 + 40;

    $canvas.setAttribute("width", w);
    $canvas.setAttribute("height", h);

    const ctx = $canvas.getContext("2d");
    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, w, h);

    for (let r = 0; r < this.n_rows; r += 1) {
      for (let c = 0; c < this.n_cols; c += 1) {
        const shutter_idx = r * this.n_cols + c;
        const shutter = this.shutters[shutter_idx];
        shutter.render(ctx, 20 + c * 210, 20 + r * 210);
      }
    }
  }

  tick() {
    for (let shutter of this.shutters) {
      shutter.tick();
    }
  }
}

class Shutter {
  // motor
  theta = 0;
  v = 0; // Hz
  a = 0;

  // gear
  gear_ratio = 0.1; // 100 rotations of motor = 1 rotation of shutter

  // shutter
  pane_h = 12;
  // TODO: This number isn't used. But there should be 24 panes...
  n_panes = 24;
  shutter_y = 0;

  max_y = 150;

  // sonification?
  // canvas?

  // time
  last_t;
  // pane-state
  n_panes_visibles = 0;

  constructor(opts) {
    // initialize web audio api?
    console.log("INIT");
    this.last_t = new Date().getTime() / 1000;

    if (opts) {
      this.samples = opts.samples;

      if (this.samples) {
        const oscillator = this.samples.aCtx.createOscillator();
        oscillator.type = "sine";
        oscillator.frequency.setValueAtTime(
          Math.abs(this.v),
          this.samples.aCtx.currentTime,
        );

        // Create a gain node to control the volume
        const gainNode = this.samples.aCtx.createGain();
        gainNode.gain.setValueAtTime(0.4, this.samples.aCtx.currentTime);

        oscillator.connect(gainNode);
        gainNode.connect(this.samples.aCtx.destination);

        // Start the oscillator
        oscillator.start();

        this.osc = oscillator;
      }
    }
  }

  tick() {
    const t = new Date().getTime() / 1000;
    const dt = t - this.last_t;
    this.last_t = t;

    const dtheta = this.v * 2 * Math.PI * dt;

    this.theta += dtheta % (2 * Math.PI);

    this.shutter_y += dtheta * this.gear_ratio;

    if (
      (this.v > 0 && this.shutter_y >= this.max_y - 0.1) ||
      (this.v < 0 && this.shutter_y < 0)
    ) {
      this.v *= -1;
    }

    if (this.osc) {
      this.osc.frequency.setValueAtTime(this.v, this.samples.aCtx.currentTime);
    }
  }

  render(ctx, x, y) {
    const w = 200;
    const h = 200;

    ctx.strokeStyle = "cyan";
    ctx.fillStyle = "cyan";

    // MOTOR
    const cx = 30 + x;
    const cy = 30 + y;
    const r = 10;

    ctx.beginPath();
    ctx.arc(cx, cy, r, 0, 2 * Math.PI);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(cx, cy);
    ctx.lineTo(cx + r * Math.cos(this.theta), cy + r * Math.sin(this.theta));
    ctx.stroke();

    ctx.font = "12px Helvetica";

    ctx.fillText(`${this.v}Hz`, cx - r - 2, cy + r + 10);

    ctx.strokeStyle = "yellow";
    ctx.fillStyle = "yellow";

    // SHUTTER
    const sx = cx + r + 10;
    const sy = cy - r + 4;
    const sw = w - (sx - cx) - 25;
    const sh = 2 * r - 8;
    ctx.strokeRect(sx, sy, sw, sh);
    ctx.fillText(`${this.v * this.gear_ratio}Hz`, sx + 10, sy + sh - 2);

    ctx.strokeStyle = "magenta";
    ctx.fillStyle = "magenta";

    const px = sx + 10;
    const py = sy + sh + 4;
    const pw = sw - 20;
    const ph = this.shutter_y;
    ctx.strokeRect(px, py, pw, ph);

    let cnt = 0;
    for (let dy = ph - this.pane_h; dy > 0; dy -= this.pane_h) {
      ctx.strokeRect(px + 12, py + dy, pw - 24, 1);
      cnt += 1;
    }
    if (cnt !== this.n_panes_visibles) {
      this.n_panes_visibles = cnt;
      ctx.fillText("CLICK", px + 2, py + ph + 14);

      if (this.samples) {
        // Trigger sound!

        let samp;
        if (this.shutter_y < 60) {
          samp = this.samples.tonal;
        } else {
          samp = this.samples.crash;
        }

        const source = this.samples.aCtx.createBufferSource();
        source.buffer = samp.buf;

        const gainNode = this.samples.aCtx.createGain();
        source.connect(gainNode);
        gainNode.connect(this.samples.aCtx.destination);

        const FADE_DURATION = 0.05;

        const SAMP_VOL = 0.6;

        const eIdx = Math.floor(Math.random() * samp.events.length);

        const offset = Math.max(0, samp.events[eIdx].t - FADE_DURATION);
        const duration = Math.min(
          samp.duration,
          2 * FADE_DURATION +
            (eIdx + 1 < samp.events.length
              ? samp.events[eIdx + 1].t
              : samp.duration) -
            offset,
        );

        const now = this.samples.aCtx.currentTime;

        gainNode.gain.setValueAtTime(0, now);

        // Fade in
        gainNode.gain.linearRampToValueAtTime(SAMP_VOL, now + FADE_DURATION);

        // Fade out
        gainNode.gain.setValueAtTime(SAMP_VOL, now + duration - FADE_DURATION);
        gainNode.gain.linearRampToValueAtTime(0, now + duration);

        source.start(now, offset, duration);
      }
    }

    ctx.fillText(`${Math.round(this.shutter_y)}`, px + 2, py + ph - 4);
  }
}

const MORN_UID = "GX010006.MP4";
const NOON_UID = "GX010012.MP4";

const DownloadPostLink = ({ uid, children }) => (
  <a
    href="#"
    onClick={() => {
      const dl = async () => {
        const signedUrl = await (
          await fetch("/dl", {
            method: "post",
            body: JSON.stringify({ uid, folder: "goat" }),
          })
        ).json();
        console.log("got signed url", signedUrl);

        const link = document.createElement("a");
        link.href = signedUrl;
        link.setAttribute("download", `${uid}.mp4`);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      };
      dl();
    }}
  >
    {children}
  </a>
);

const DownloadGetLink = ({ uid, children }) => (
  <a href={`/blob?folder=goat&uid=${uid}`}>{children}</a>
);

const ExtLink = ({ href, children }) => {
  return (
    <a href={href} target="_blank">
      {children}
    </a>
  );
};

const FLUX_NOTES = [
  {
    name: "above-below.webp",
    text: "The image is out of this world and confuses over and under.",
  },
  { name: "deformed.webp", text: "Errant limbs, horns..." },
  {
    name: "carbon-copies.webp",
    text: "One feels each goat as its ideal other, decorative differences.",
  },
  {
    name: "the-cliche.jpg",
    text: "In fact I think the word cliché will need to be redefined in these probabilistic times.",
  },
  {
    name: "pro-detailed.jpg",
    text: "The ostensible detail slumps over the lack of purpose (for us, for the goats), the uniqueness of this render evidence of our individual irrelevance.",
  },
  {
    name: "goat1.png",
    text: "Maybe when we say these images are 'impressive' we mean something more like 'impassive.' No particular thirst in the desert, relief in the well, no aggression, the instance is so clearly subservient to its template.",
  },
  {
    name: "goat2.png",
    text: "So here we are in the post-cliché space of aligned probability.",
  },
];

const FluxStrip = () => {
  return (
    <div>
      {FLUX_NOTES.map(({ name, text }) => (
        <>
          <img width={440} src={`/blob?folder=flux&uid=${name}`} />
          <div>{text}</div>
        </>
      ))}
    </div>
  );
};

const Home = () => {
  return (
    <div style={MAIN}>
      <p style={{ textAlign: "right" }}>2024-09-06</p>
      <p>
        <b>Control theory: </b>
        by varying the motor speed of a rolling shutter very precisely, we
        expect to be able to effect a stunning visual and sonic effect. We
        should be able to update the motor speed more than 50 times per second,
        allowing the shutter to have a voice of sorts, to be an instrument,
        or&mdash;with all 18 doors of the market in concert&mdash;each shutter
        becomes a tooth, you the viewer in the mouth of a powerful and subtly
        controlled instrument.
      </p>
      <p>
        To prove this out, we've set up a model shutter in Birzeit. This
        cloud-based web application can emit Serial (RS-232) codes that the
        shutter understands and acts on:
        <ul>
          <li>
            <Link to="/serial-shutter">View and test the control protocol</Link>
          </li>
        </ul>
      </p>
      <p>
        <b>Sonic simulation:</b> To have some idea of the sound that will come
        from each door, we implement a simulation of a single door that blends
        recorded sounds from the actual market doors in Sharjah with a tone
        indicating motor speed. Co-developing a simulator with the control
        mechanism will allow remote composition and sequencing, will allow the
        work to be used as a platform.
        <ul>
          <li>
            <Link to="/shutter-sound">Shutter sound simulation</Link>
          </li>
        </ul>
      </p>
      <p>
        <b>Field recording sample playback:</b> We can also drive a door with
        the fundamental frequencies of a field recording. Here is a first
        glimpses (through the simulation) of using audio samples to control the
        shutter.
        <ul>
          <li>
            <Link to="/shutter-orchestra">Shutter sample player</Link>
          </li>
        </ul>
      </p>

      <p>
        <b>18 shutters:</b> Finally: all 18 shutters. We can simulate all of
        them at once. This is a first glimpse / listen at the full market hall.
        We imagine deploying a sequencer specially designed for composing to the
        Sharjah shutters.
      </p>
      <ul>
        <li>
          <Link to="/shutter-hall">Full hall simulation</Link>
        </li>
      </ul>

      <p style={{ marginTop: "2em" }}>Your correspondent,</p>
      <p>
        <i>R.M.O.</i>
      </p>
    </div>
  );
};

const ShutterSim2 = () => {
  const [load, setLoad] = useState(false);
  const [start, setStart] = useState(false);

  const [shutter, setShutter] = useState();

  const [velocity, setVelocity] = useState(50);

  const canvas = useRef();

  useEffect(() => {
    if (!shutter) return;

    const intId = window.setInterval(() => {
      if (!canvas.current) return;

      const $can = canvas.current;

      shutter.tick();
      shutter.render($can);
    }, 1000 / 30);

    return () => window.clearInterval(intId);
  }, [shutter]);

  useEffect(() => {
    if (!shutter) return;

    let i = 0;
    for (const shut of shutter.shutters) {
      shut.v = ((100 + i * 20) * velocity) / 100;
      i += 1;
    }
  }, [velocity, shutter]);

  const MAX_VALUE = 300;

  return (
    <div>
      {start ? (
        ""
      ) : (
        <button
          disabled={load}
          onClick={() => {
            const fn = async () => {
              setLoad(true);

              const samples = new ShutterSamples();
              samples.initAudio();
              await samples.load();

              const shut = new MultiShutter({ samples });
              setShutter(shut);

              shut.tick();

              //shut.render(canvas.current);

              setStart(true);
              setLoad(false);
            };

            fn();
          }}
        >
          {load ? "Loading sounds..." : "Start audio engine"}
        </button>
      )}

      <canvas style={{ maxWidth: "100%" }} ref={canvas} />

      <div>
        <div>
          velocity:
          <input
            type="range"
            min="0"
            max="100"
            value={velocity}
            onChange={(e) => setVelocity(Number(e.target.value))}
          />
          <input
            value={velocity}
            onChange={(e) => setVelocity(Number(e.target.value))}
          />
        </div>
      </div>
    </div>
  );
};
const ShutterSim1 = () => {
  const [shutter, setShutter] = useState();
  const [playing, setPlaying] = useState(false);
  const $canvas = useRef();

  useEffect(() => {
    const shut = new MultiShutter();
    setShutter(shut);

    // set up timer
    const tick = () => {
      shut.tick();
      if ($canvas.current) {
        shut.render($canvas.current);
      }
      window.requestAnimationFrame(tick);
    };

    tick();
  }, []);

  if (!shutter) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <canvas width={400} height={400} ref={$canvas} />
      <button
        onClick={() => {
          if (playing) {
            shutter.stopAudio();
            setPlaying(false);
          } else {
            shutter.initAudio();
            setPlaying(true);
          }
        }}
      >
        {playing ? "stop" : "play"}
      </button>
    </div>
  );
};

const Frame = ({ prefix, opts }) => {
  const [images, setImages] = useState();

  useEffect(() => {
    const load = async () => {};
    load();
  }, [prefix]);

  if (false) {
    //!images) {
    return <i>Loading {prefix}...</i>;
  }

  return <pre>{JSON.stringify(opts)}</pre>;
};

const Control = ({ uid }) => {
  const [meta, setMeta] = useState();
  const [images, setImages] = useState([]);
  const [activeFrames, setActiveFrames] = useState([]);

  useEffect(() => {
    const fn = async () => {
      const res = await (
        await fetch("/meta", {
          method: "post",
          body: JSON.stringify({ uid }),
        })
      ).json();

      setMeta(res);
    };

    fn();
  }, [uid]);

  if (!meta) {
    return <i>Loading metadata...</i>;
  }

  return (
    <div>
      {images.map((uid) => (
        <div>
          <img width={440} src={`/blob?folder=scratch&uid=${uid}`} />
          {uid}
        </div>
      ))}
      {activeFrames.map((params) => (
        <Frame {...params} />
      ))}
      {Object.entries(meta).map(([vid, frames]) => (
        <li>
          <b>{vid}</b>: {Object.keys(frames).length} frames (every 5 seconds
          {uid === "a100renders" ? "" : " starting at 60 seconds"})
          {Object.entries(frames).map(([frIdx, opts]) => (
            <>
              <b
                onClick={() =>
                  setActiveFrames([
                    { prefix: `${uid}/${vid}/${frIdx}`, opts },
                    ...activeFrames,
                  ])
                }
              >
                {frIdx}
              </b>{" "}
              {Object.keys(opts).map((keyName) => (
                <span
                  onClick={() =>
                    setImages([`${uid}/${vid}/${frIdx}/${keyName}`, ...images])
                  }
                >
                  {keyName}{" "}
                </span>
              ))}
            </>
          ))}
        </li>
      ))}
    </div>
  );
};

class SerialQueue {
  port;
  nextLine;

  active;
  mock = false;

  rate = 50; // Hz

  log = [];

  constructor(port) {
    this.port = port;
    if (this.port === "MOCK") {
      this.mock = true;
    }

    this.log = [];

    this.active = true;
    this.tick();
  }

  queuedWrite(line) {
    this.nextLine = line;
  }

  async tick() {
    if (!this.active) {
      return;
    }

    const line = this.nextLine;
    this.nextLine = null;

    if (line) {
      console.log("writing", line);
      this.log.push(line);

      if (!this.mock) {
        if (!this.writer) {
          const textEncoder = new TextEncoderStream();
          const writableStreamClosed = textEncoder.readable.pipeTo(
            this.port.writable,
          );

          this.writer = textEncoder.writable.getWriter();
        }

        await this.writer.write(line);
      }
    }

    window.setTimeout(() => this.tick(), 1000 / this.rate);
  }

  stop() {
    this.active = false;
  }
}

const vel2line = (vel) => `S[0]=${vel};\n`;

const SerialShutter = () => {
  const [port, setPort] = useState();
  const [baudRate, setBaudRate] = useState(9600);

  const [curVelocity, setCurVelocity] = useState(0);
  const [dragging, setDragging] = useState(false);

  const [serialLog, setSerialLog] = useState("");

  const hasSerial = "serial" in navigator;

  useEffect(() => {
    if (!port) return;

    const intervalId = window.setInterval(() => {
      //console.log("LOG TICK", port.log);
      setSerialLog(port.log.join(""));
    }, 1000 / 10);

    return () => window.clearInterval(intervalId);
  }, [port]);

  if (!port) {
    return (
      <div style={MAIN}>
        Please plug in the PLC and then select it as a serial port.
        <div style={{ margin: 20 }}>
          baud rate:{" "}
          <input
            value={baudRate}
            onChange={(e) => setBaudRate(Number(e.target.value))}
          />
          <br />
          other serial options are set to{" "}
          <ExtLink href="https://wicg.github.io/serial/#serialoptions-dictionary">
            the defaults
          </ExtLink>
          .
        </div>
        {!hasSerial ? (
          <div style={{ color: "red" }}>
            This web browser doesn't support the Web Serial API. Please try in
            Chrome, or use "mock port" for a simulation.
          </div>
        ) : (
          ""
        )}
        <button
          disabled={!hasSerial}
          onClick={() => {
            const fn = async () => {
              try {
                const mPort = await navigator.serial.requestPort();
                await mPort.open({ baudRate });

                const writer = new SerialQueue(mPort);
                window.serialQueue = writer;
                setPort(writer);
              } catch (e) {
                console.log("port not selected", e);
              }
            };

            fn();
          }}
        >
          <b>Select port</b>
        </button>
        <button
          onClick={() => {
            const writer = new SerialQueue("MOCK");
            setPort(writer);
            window.serialQueue = writer;
          }}
        >
          Mock port
        </button>
      </div>
    );
  }

  // Make a little grabbable speed control

  const MIN_VALUE = -1000;
  const MAX_VALUE = 1000;

  return (
    <div style={MAIN}>
      <p>
        Grab the velocity value to set the speed (mouse up/down). Release it to
        stop the shutter. Think of this like a digital potentiometer: once these
        serial protocol is able to control the shutter, it is a small step to
        orchestrating complex and multi-door arrangements. The protocol sends
        text-lines over RS-232 (serial) with values between -1000 and 1000,
        representing shutter-motor velocity.
      </p>

      <div
        style={{
          cursor: "grab",
          padding: 30,
          width: 50,
          margin: "auto",
          border: "1px dashed black",
          backgroundColor: dragging ? "magenta" : "cyan",
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();

          setDragging(true);

          const curY = e.clientY;

          const maxY = window.innerHeight;

          window.onmousemove = (e2) => {
            e2.stopPropagation();
            e2.preventDefault();

            const dy = e2.clientY - curY;

            const py = Math.min(
              1,
              Math.max(-1, dy > 0 ? dy / (maxY - curY) : dy / curY),
            );

            const vel = Math.round(py * MAX_VALUE);

            setCurVelocity(vel);
            port.queuedWrite(vel2line(vel));
          };
          window.onmouseup = (e2) => {
            e2.stopPropagation();
            e2.preventDefault();

            window.onmousemove = null;
            window.onmouseup = null;

            setCurVelocity(0);
            port.queuedWrite(vel2line(0));

            setDragging(false);
          };
        }}
      >
        {curVelocity}
      </div>

      <pre
        style={{
          backgroundColor: "black",
          color: "lime",
          height: 200,
          overflowY: "auto",
          padding: 10,
          margin: 10,
        }}
      >
        {serialLog}
      </pre>
    </div>
  );
};

const ShutterSound = () => {
  const [load, setLoad] = useState(false);
  const [start, setStart] = useState(false);

  const [shutter, setShutter] = useState();

  const canvas = useRef();

  useEffect(() => {
    if (!shutter) return;

    const intId = window.setInterval(() => {
      if (!canvas.current) return;

      const $can = canvas.current;
      $can.setAttribute("width", 220);
      $can.setAttribute("height", 220);
      const ctx = $can.getContext("2d");

      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, 220, 220);

      shutter.tick();
      shutter.render(ctx, 0, 0);
    }, 1000 / 30);

    return () => window.clearInterval(intId);
  }, [shutter]);

  const MAX_VALUE = 300;

  return (
    <div>
      {start ? (
        ""
      ) : (
        <button
          disabled={load}
          onClick={() => {
            const fn = async () => {
              setLoad(true);

              const samples = new ShutterSamples();
              samples.initAudio();
              await samples.load();

              const shut = new Shutter({ samples });
              setShutter(shut);

              shut.tick();

              //shut.render(canvas.current);

              setStart(true);
              setLoad(false);
            };

            fn();
          }}
        >
          {load ? "Loading sounds..." : "Start audio engine"}
        </button>
      )}

      <p>drag the door up and down</p>
      <canvas
        ref={canvas}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();

          const curY = e.clientY;
          const maxY = window.innerHeight;

          window.onmousemove = (e2) => {
            e2.stopPropagation();
            e2.preventDefault();

            const dy = e2.clientY - curY;

            const py = Math.min(
              1,
              Math.max(-1, dy > 0 ? dy / (maxY - curY) : dy / curY),
            );

            const vel = Math.round(py * MAX_VALUE);

            shutter.v = vel;
          };
          window.onmouseup = (e2) => {
            e2.stopPropagation();
            e2.preventDefault();

            window.onmousemove = null;
            window.onmouseup = null;

            shutter.v = 0;
          };
        }}
      />
    </div>
  );
};

const ShutterOrchestra = () => {
  const [fspec, setFspec] = useState();
  const [loading, setLoading] = useState();

  const [sampleRate, setSampleRate] = useState(50);
  const [velocity, setVelocity] = useState(50);

  const [shutter, setShutter] = useState();

  const canvas = useRef();

  const loadSpec = async (uid) => {
    setLoading(true);

    if (shutter) {
      shutter.osc.stop();
    }

    const samples = new ShutterSamples();
    samples.initAudio();
    await samples.load();

    const shut = new Shutter({ samples });
    setShutter(shut);

    shut.tick();

    const res = await (
      await fetch(`/blob?folder=samples&uid=${uid}.f0.json`)
    ).json();
    setFspec(res);

    setLoading(false);
  };

  useEffect(() => {
    // drive shutter w/interval....

    if (!shutter) return;
    if (!fspec) return;

    let idx = 0;
    let down = 1;

    const intId = window.setInterval(() => {
      if (!canvas.current) return;

      idx = (idx + (2 * sampleRate) / 100) % fspec.f0.length;

      const nearestIdx = Math.round(idx);

      if (shutter.shutter_y > 140 && down > 0) {
        down = -1;
      } else if (shutter.shutter_y < 10 && down < 0) {
        down = 1;
      }

      shutter.v = down * (fspec.f0[nearestIdx] || 0) * (velocity / 100);
      console.log("idx", idx, "v", shutter.v);

      const $can = canvas.current;
      $can.setAttribute("width", 220);
      $can.setAttribute("height", 220);
      const ctx = $can.getContext("2d");

      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, 220, 220);

      shutter.tick();
      shutter.render(ctx, 0, 0);
    }, 1000 / 50);

    return () => {
      window.clearInterval(intId);
    };
  }, [shutter, fspec, sampleRate, velocity]);

  return (
    <div>
      <div>
        Samples:
        <button onClick={() => loadSpec("yusef")}>yusef</button>
        <button onClick={() => loadSpec("Al_Salam_Street_mai_bared")}>
          al salam street
        </button>
      </div>

      <div>
        velocity:
        <input
          type="range"
          min="0"
          max="100"
          value={velocity}
          onChange={(e) => setVelocity(Number(e.target.value))}
        />
        <input
          value={velocity}
          onChange={(e) => setVelocity(Number(e.target.value))}
        />
      </div>
      <div>
        sample rate:
        <input
          type="range"
          min="0"
          max="100"
          value={sampleRate}
          onChange={(e) => setSampleRate(Number(e.target.value))}
        />
        <input
          value={sampleRate}
          onChange={(e) => setSampleRate(Number(e.target.value))}
        />
      </div>

      <canvas ref={canvas} />
    </div>
  );
};

const MAIN = {
  maxWidth: 800,
  padding: "1em",
  margin: "auto",
};

const Goats = () => {
  return (
    <div style={MAIN}>
      <p>
        <b>Goats drinking from the well.</b> Two recordings, morning and
        noontime, clouds lapsing through the former, sun eyeball projections in
        the latter, goat shadows radiant. Raw footage from the gopro hero 12
        with max lens 2, wide and widest settings (respectively):
      </p>
      <ul>
        <li>
          <DownloadGetLink uid={MORN_UID}>morning (1.1G)</DownloadGetLink>
        </li>
        <li>
          <DownloadGetLink uid={NOON_UID}>noontime (6.5G)</DownloadGetLink>
        </li>
      </ul>
      <p>
        The well is a camera aperture, a washing machine, the iris of an eye,
        astronomical contellations, a clock... but is our shutter outdated? What
        of the AI warfare of today? How does that mirror the mirrorless image
        capture, the diffusing grid of the power-hungry AI. How does{" "}
        <ExtLink href="https://blackforestlabs.ai/#get-flux">FLUX</ExtLink> see
        our goats?
      </p>
      <ul>
        <li>
          <Link to="/flux-strip">
            Prompt-based images of goats drinking from a well, with interpretive
            captions
          </Link>
        </li>
      </ul>
      <p>
        What of metaphor? How will imagination develop when images can so easily
        emerge from words? Zhang, Rao, and Agrawala propose a{" "}
        <ExtLink href="https://arxiv.org/abs/2302.05543">ControlNet</ExtLink>{" "}
        for visually shaping AI diffusion images. But does it work with a real
        leap of the imagination? Does it take us out of the probabilistic space
        to one more our own? We use the goat footage to provide{" "}
        <ExtLink href="https://docs.opencv.org/4.x/d7/de1/tutorial_js_canny.html">
          "canny" edges
        </ExtLink>
        , and then offer the evocations for a literal render. Too literal? Not
        enough control?
      </p>
      <ul>
        <li>
          <Link to="/control-1">First goat control render</Link>
        </li>
        <li>
          <Link to="/control-2">Second goat control render</Link>
        </li>
      </ul>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { path: "", element: <Home /> },
      { path: "goats", element: <Goats /> },
      { path: "flux-strip", element: <FluxStrip /> },
      { path: "control-1", element: <Control uid="a100renders" /> },
      { path: "control-2", element: <Control uid="a100renders-02-masked" /> },
      { path: "shutter-sim-1", element: <ShutterSim1 /> },
      { path: "shutter-hall", element: <ShutterSim2 /> },
      { path: "serial-shutter", element: <SerialShutter /> },
      { path: "shutter-sound", element: <ShutterSound /> },
      { path: "shutter-orchestra", element: <ShutterOrchestra /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
